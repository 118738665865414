(function( $ ){
    $.fn.confirmHandler= function(options) {
        $(this).on('click', function(event) {
            event.preventDefault();

            let element = $(event.target), requestUrl = '';

            Swal.fire({
                title: element.data('title'),
                text: element.data('text'),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: element.data('confirm-title'),
                cancelButtonText: element.data('cancel-title')
            }).then((result) => {
                if (result.value) {
                    requestUrl = $(event.target).attr('data-url');
                    window.location = requestUrl;
                }
            });
        });
    };
})(jQuery);

$(document).ready(function() {
    $("[data-handler='confirm']").confirmHandler();
});
