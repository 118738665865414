// CSS
require('../css/app.css');

const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;

require('bootstrap');

const datepicker = require('bootstrap-datepicker');

require('./tabler.js');
const Swal = require('sweetalert2');
global.Swal = Swal;
require('./confirmHandler');

global.bsCustomFileInput = require('bs-custom-file-input');
